



































































































































































































































































import { Api } from '@/edshed-common/api/methods'
import {
  CreateGrammarLessonRequest, UpdateGrammarLessonRequest, UpdateGrammarTopicRequest, GrammarLessonInfo,
  GrammarTopicInfo, QuizCurriculumNodeGrammarLinkingInfo, GrammarGameQuestionCounts, GrammarGameIdent
} from '@/edshed-common/api/types/grammar'
import { capitaliseFirstLetter, grammarTopicTypeToSubjectTitle, trimStringToLastSpace } from '@/edshed-common/utils'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'GrammarNodeLinker',
  components: {}
})
export default class GrammarNodeLinker extends Mixins(ComponentHelper) {
  qcn: QuizCurriculumNodeGrammarLinkingInfo[] = []
  grammarTopics: GrammarTopicInfo[] = []
  grammarLessons: GrammarLessonInfo[] = []

  lessonsMatchCount = 0

  loading = false

  async fetchData () {
    this.loading = true
    const data = await Api.getNodesForLinking('en_GB')

    if (data) {
      this.qcn = data.quiz_curriculum_nodes
      this.grammarTopics = data.topics
      this.grammarLessons = data.lessons

      this.loading = false
    }
  }

  selectedTopicId: number | null = null

  get currentTopic () {
    if (this.selectedTopicId === null) {
      return null
    }
    return this.grammarTopics.find(t => t.id === this.selectedTopicId) || null
  }

  get lessonsForCurrentTopic () {
    if (this.currentTopic === null) {
      return []
    }
    return this.grammarLessons.filter(gl => gl.topic_id === this.currentTopic?.id)
  }

  get qcnForCurrentTopic () {
    if (this.currentTopic === null) {
      return null
    }
    return this.qcn.find(q => q.id === this.currentTopic?.quiz_curriculum_node_id)
  }

  get currentTopicsQcnParentHref () {
    if (this.currentTopic === null) {
      return '#'
    }
    return `/curriculum_nodes?parent_id=${this.currentTopic.quiz_curriculum_node_id}`
  }

  get childrenNodesForCurrentTopic () {
    if (this.currentTopic === null) {
      return []
    }
    return this.qcn.filter(q => q.parent_id === this.currentTopic?.quiz_curriculum_node_id)
  }

  get unlinkedChildrenNodesForCurrentTopic () {
    return this.childrenNodesForCurrentTopic.filter(q => q.linked === null)
  }

  get allLessonsForCurrentTopicLinked () {
    return this.lessonsForCurrentTopic.every(l => l.quiz_curriculum_node_id)
  }

  openNode (nodeId: number) {
    const node = this.qcn.find(q => q.id === nodeId)
    if (!node) {
      this.showInternalErrorToast()
      return
    }
    window.open(`/curriculum_nodes?parent_id=${node.parent_id}`, '_blank')
  }

  nodeName (nodeId: number) {
    const node = this.qcn.find(q => q.id === nodeId)
    return node ? node.name : 'Unknown'
  }

  // topic table

  selectTopic (topic_id: number) {
    this.selectedTopicId = topic_id
  }

  unselectTopic () {
    this.selectedTopicId = null
  }

  typeText (topic: GrammarTopicInfo) {
    return grammarTopicTypeToSubjectTitle(topic.type)
  }

  topicsLessonsStatus (topic: GrammarTopicInfo) {
    const lessons = this.grammarLessons.filter(gl => gl.topic_id === topic.id)
    const linkedLessons = lessons.filter(l => l.quiz_curriculum_node_id)

    let response = `${linkedLessons.length} / ${lessons.length}`

    if (linkedLessons.length === lessons.length && lessons.length > 0) {
      response += ' ✅'
    }

    return response
  }

  topicsChildNodesStatus (topic: GrammarTopicInfo) {
    const childrenNodes = this.qcn.filter(q => q.parent_id === topic.quiz_curriculum_node_id)
    const linkedChildrenNodes = childrenNodes.filter(q => q.linked !== null)

    let response = `${linkedChildrenNodes.length} / ${childrenNodes.length}`

    if (linkedChildrenNodes.length === childrenNodes.length && childrenNodes.length > 0) {
      response += ' ✅'
    }

    return response
  }

  titleMinLength = 10
  titleMaxLength = 70

  showTitleLengthErrorToast () {
    this.$buefy.toast.open({
      message: `Title must be ${this.titleMinLength} - ${this.titleMaxLength} characters long`,
      type: 'is-danger'
    })
  }

  showInternalErrorToast () {
    this.$buefy.toast.open({
      message: 'Internal error. Please refresh the page and try again.',
      type: 'is-danger'
    })
  }

  editTopicTitle (topicId: number, newTitle: string) {
    if (newTitle.length < this.titleMinLength || newTitle.length > 50) {
      this.showTitleLengthErrorToast()
      return
    }

    const topic = this.grammarTopics.find(t => t.id === topicId)
    if (!topic) {
      this.showInternalErrorToast()
      return
    }

    const request: UpdateGrammarTopicRequest = {
      title: newTitle
    }

    Api.updateGrammarTopic(topicId, request)
      .then(() => {
        this.fetchData()
      })
      .catch((e) => {
        console.error(e)
        this.showInternalErrorToast()
      })
  }

  clickEditTopicTitle (topicId: number) {
    const topic = this.grammarTopics.find(t => t.id === topicId)
    if (!topic) {
      this.showInternalErrorToast()
      return
    }
    const oldTitle = trimStringToLastSpace(topic.title, 60, '...')
    this.$buefy.dialog.prompt({
      message: 'New Topic Title:',
      inputAttrs: {
        placeholder: oldTitle,
        maxlength: this.titleMaxLength
      },
      trapFocus: true,
      onConfirm: (value) => { this.editTopicTitle(topicId, value) }
    })
  }

  // end topic table

  // lesson table
  async createLesson (title: string, topicId: number) {
    if (title.length < this.titleMinLength || title.length > this.titleMaxLength) {
      this.showInternalErrorToast()
      return
    }

    try {
      await Api.createGrammarLesson({
        title,
        topic_id: topicId,
        quiz_curriculum_node_id: null
      })
      this.fetchData()
    } catch (e) {
      console.error(e)
      this.showInternalErrorToast()
    }
  }

  clickAddLesson () {
    const topic = this.currentTopic
    if (!topic) {
      this.showTitleLengthErrorToast()
      return
    }
    this.$buefy.dialog.prompt({
      message: 'New Lesson Title:',
      inputAttrs: {
        placeholder: 'e.g: Identify and define nouns',
        maxlength: this.titleMaxLength
      },
      trapFocus: true,
      onConfirm: (value) => { this.createLesson(value, topic.id) }
    })
  }

  async editLessonTitle (lessonId: number, newTitle: string) {
    if (newTitle.length < this.titleMinLength || newTitle.length > 50) {
      this.showTitleLengthErrorToast()
      return
    }

    const lesson = this.grammarLessons.find(l => l.id === lessonId)
    if (!lesson) {
      this.showInternalErrorToast()
      return
    }

    const request: UpdateGrammarLessonRequest = {
      title: newTitle
    }

    try {
      await Api.updateGrammarLesson(lessonId, request)
      this.fetchData()
    } catch (e) {
      console.error(e)
      this.showInternalErrorToast()
    }
  }

  clickEditLessonTitle (lessonId: number) {
    const lesson = this.grammarLessons.find(l => l.id === lessonId)
    if (!lesson) {
      this.showInternalErrorToast()
      return
    }
    const oldTitle = trimStringToLastSpace(lesson.title, 60, '...')
    this.$buefy.dialog.prompt({
      message: 'New Lesson Title:',
      inputAttrs: {
        placeholder: oldTitle,
        maxlength: this.titleMaxLength
      },
      trapFocus: true,
      onConfirm: (value) => { this.editLessonTitle(lessonId, value) }
    })
  }

  questionCountsRowHtml (questionCounts: GrammarGameQuestionCounts | null) {
    if (questionCounts === null || Object.keys(questionCounts).length === 0) {
      return 'No questions'
    }
    let totalQuestions = 0
    let response = ''
    for (const gameIdent of GrammarGameIdent) {
      const count = questionCounts[gameIdent]
      if (count) {
        totalQuestions += count
        response += `${capitaliseFirstLetter(gameIdent)}: ${count}<br>`
      }
    }
    if (Object.keys(questionCounts).length > 1) {
      response += `(Total: ${totalQuestions})`
    }
    return response
  }

  openLessonInGrammarQuestionTool (lessonId: number) {
    const lesson = this.grammarLessons.find(l => l.id === lessonId)
    if (!lesson) {
      this.showInternalErrorToast()
      return
    }
    let gameIdent: GrammarGameIdent = 'dog'
    if (lesson.question_counts !== null && Object.keys(lesson.question_counts).length > 0) {
      for (const key of GrammarGameIdent) {
        if (lesson.question_counts[key] !== undefined) {
          gameIdent = key
          break
        }
      }
    }
    window.open(`grammar?g=${gameIdent}&n=${lessonId}&p=1`, '_blank')
  }

  // end lesson table

  // lesson to node linking

  lessonToLink: number | null = null
  nodeToLink: number | null = null

  get lessonToLinkTitle () {
    if (this.lessonToLink === null) {
      return ''
    }
    const lesson = this.grammarLessons.find(l => l.id === this.lessonToLink)
    return lesson ? lesson.title : ''
  }

  closeLessonLinkModal () {
    this.lessonToLink = null
  }

  openLessonLinkModal (lessonId: number) {
    this.nodeToLink = null
    this.lessonToLink = lessonId
  }

  async linkLessonToNode () {
    if (this.lessonToLink === null || this.nodeToLink === null) {
      this.lessonToLink = null
      this.nodeToLink = null
      return
    }

    try {
      await Api.linkGrammarLessonToQuizCurriculumNode(this.lessonToLink, this.nodeToLink)
      this.lessonToLink = null
      this.nodeToLink = null
      this.fetchData()
    } catch (e) {
      console.error(e)
      this.showInternalErrorToast()
    }
  }

  // end lesson to node linking

  // mounted
  mounted () {
    // this.$nextTick(async () => {
    //   await this.fetchData()
    // })

    this.fetchData()
  }
}
